import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { alpha, createStyles, InputBase, StyleRules, Theme } from '@material-ui/core';
import { Button, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AllOutIcon from '@material-ui/icons/AllOut';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import BusinessIcon from '@material-ui/icons/Business';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MoreIcon from '@material-ui/icons/MoreVert';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SearchIcon from '@material-ui/icons/Search';
import { Modal } from 'antd';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { navigate } from 'gatsby-link';
import i18n from 'i18next';

import { getUser, logout } from '../actions/userActions';
import logo from '../images/0419Fintegrity_logo-03.png';
import { AppState } from '../reducers';
import { FUserTypeEnum } from '../reducers/userReducer';
import { userTypeKnownAction } from '../reducers/userReducer';
import CITY from '../utils/city';
import { TOKEN_KEY } from '../utils/graphql';
import ConsultantCityListView from '../views/consultant/ConsultantCityListView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    grow: {
      width: '100vw',
    },
    projectMenu: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    menuLeft: {
      display: 'flex',
      alignItems: 'center',
    },
    menuRight: {
      display: 'flex',
      alignItems: 'center',
    },
    toolbar: {
      padding: '0',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 auto',
      height: theme.spacing(3),
      alignItems: 'center',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
      [theme.breakpoints.up('md')]: {
        width: '1286px',
        justifyContent: 'space-between',
      },
    },
    img: {
      height: '40px',
      width: '90px',
      marginBottom: 0,
      objectFit: 'cover',

      [theme.breakpoints.up('md')]: {
        marginRight: '2rem',
      },
    },

    menuButton: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      letterSpacing: '0.1rem',
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        marginRight: '0.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    menuCity: {
      marginLeft: '-1rem',
      textDecoration: 'none',
      fontSize: '0.75rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      letterSpacing: '0.1rem',
      cursor: 'pointer',
      '&:hover': {
        color: '#9b9bbe',
      },
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        marginRight: '2rem',
        marginLeft: '1rem',
        width: '15rem',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: '1rems',
      transition: theme.transitions.create('width'),
      // minWidth: '5rem',
      // [theme.breakpoints.up('md')]: {
      //   width: '20rem',
      // },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    usernameDisplay: {
      display: 'flex',
      alignItems: 'center',
    },
    action: {
      cursor: 'pointer',
      borderRadius: '5px',
      padding: '0.5rem 1rem',
      border: '1px solid rgba(217, 217, 217,0.8)',
      '&:hover': {
        backgroundColor: 'rgba(217, 217, 217,0.5)',
      },
    },
    actionText: {
      color: '#242f6e',
      [theme.breakpoints.up('md')]: {
        margin: '0 0.25rem',
      },
      '&:hover': {
        fontWeight: 'bold',
      },
    },
    // registerText: {
    //   [theme.breakpoints.down('md')]: {
    //     display: 'none',
    //   },
    // },
    AppBarBackground: {
      background: 'white',
      color: '#3f424f',
      padding: '0',
    },
    hide: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mobileMenuItem: {
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
  });

const ConsultantHeader = ({ classes, userDisplays = false }: Props): React.ReactElement => {
  const { t } = useTranslation();
  i18n.loadNamespaces('header');
  const dispatch = useDispatch();
  const user = useSelector((appState: AppState) => appState.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [email, setEmail] = useState<string>(user.email || '');

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  isMobileMenuOpen;
  const [cityName, setCityName] = useState<string>('3101');
  const [cityOpenDialog, setCityOpenDialog] = useState<boolean>(false);

  const selectCity = (name: string) => {
    setCityName(name);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = (): void => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogout = async (): Promise<void> => {
    setAnchorEl(null);
    await dispatch(logout(user.userType));
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  handleMobileMenuOpen;
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      style={{ zIndex: '99999' }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {user.userType === FUserTypeEnum.COMPANY && (
        <span>
          <MenuItem
            onClick={(): void => {
              navigate('/company/info');
            }}
          >
            <ChromeReaderModeIcon />
            <span>企业认证</span>
          </MenuItem>

          <MenuItem
            onClick={(): void => {
              navigate('/company/verification');
            }}
          >
            <LocationCityIcon />
            <span>企业信息</span>
          </MenuItem>
        </span>
      )}
      {user.userType == FUserTypeEnum.CONSULTANT ? (
        <span>
          <MenuItem
            onClick={() => {
              navigate('/consultant/biddingStatus');
            }}
          >
            <PlaylistAddCheckIcon />
            <span>我的申请</span>
          </MenuItem>

          <MenuItem
            onClick={() => {
              navigate('/consultant/myProjects');
            }}
          >
            <ListAltIcon />
            <span>我的项目</span>
          </MenuItem>
          <MenuItem
            onClick={(): void => {
              navigate('/consultant/accountManagement');
            }}
          >
            <AccountBoxIcon />
            <span>账号管理</span>
          </MenuItem>
          {/* <MenuItem
            onClick={(): void => {
              navigate('/consultant/verification');
            }}
          >
            <AccountBoxIcon />
            <span>简历</span>
          </MenuItem> */}
          {/* <MenuItem
            onClick={(): void => {
              navigate('/consultant/authentication');
            }}
          >
            <HowToRegIcon />
            <span> {t('header:real_name_authentication')}</span>
          </MenuItem> */}
          <MenuItem
            onClick={(): void => {
              navigate('/consultant/settlementProject');
            }}
          >
            <ListAltIcon />
            <span>{t('header:project_settlement')}</span>
          </MenuItem>
        </span>
      ) : null}
      {!user.consultant?.id && user.id && user.userType != FUserTypeEnum.CONSULTANT && (
        <MenuItem
          onClick={(): void => {
            navigate('/consultant/verification');
          }}
        >
          {t('header:consultant_information')}
        </MenuItem>
      )}
      {user.id && (
        <MenuItem onClick={handleLogout}>
          <AllOutIcon />
          <span>{t('header:sign_out')}</span>
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const keyByEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && e.currentTarget.value) {
      console.log(e.currentTarget.value);
    }
  };

  useEffect(() => {
    setEmail(user.email || '');
    return () => {
      setEmail('');
    };
  }, [user]);

  useEffect(() => {
    localStorage.getItem(TOKEN_KEY) && !user.id ? dispatch(getUser()) : null;
  }, []);

  return (
    <div className={classes.grow}>
      <Modal
        open={cityOpenDialog}
        footer={null}
        width={800}
        centered
        title="选择城市"
        style={{
          boxShadow: '0 3px 5px rgba(0, 0, 0, .2)',
        }}
        onCancel={() => setCityOpenDialog(false)}
      >
        <ConsultantCityListView
          selectValue={selectCity}
          onClose={() => {
            setCityOpenDialog(false);
          }}
        />
      </Modal>
      <AppBar position="static" className={classes.AppBarBackground}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.menuLeft}>
            <Link
              to="/"
              onClick={() => {
                dispatch(userTypeKnownAction(FUserTypeEnum.CONSULTANT));
              }}
              className={classes.hide}
            >
              <img src={logo} alt="logo" className={classes.img} />
            </Link>
            <a
              className={classes.menuCity}
              onClick={() => {
                setCityOpenDialog(true);
              }}
            >
              {user.userType == FUserTypeEnum.CONSULTANT ? (
                cityName ? (
                  <div style={{ letterSpacing: '2px', display: 'flex', alignItems: 'center' }}>
                    {CITY.find(c => c.code == cityName)?.name}
                    <ArrowDropDownIcon fontSize="small" />
                  </div>
                ) : (
                  '选择城市'
                )
              ) : null}
            </a>
            <div>
              <Button
                className={classes.menuButton}
                aria-label="Job request list"
                onClick={() => {
                  navigate('/');
                }}
              >
                <span>首页</span>
              </Button>
            </div>
            {(user.userType !== FUserTypeEnum.COMPANY || !user.userType) && (
              <>
                <div>
                  <Button
                    className={classes.menuButton}
                    aria-label="Job request list"
                    onClick={() => {
                      navigate('/consultant/publicBiddingProjects');
                    }}
                  >
                    <span>找项目</span>
                  </Button>
                </div>

                <div>
                  <Button
                    className={classes.menuButton}
                    aria-label="Job request list"
                    style={{ marginLeft: '2rem' }}
                    onClick={() => {
                      navigate('/company/publishProject');
                    }}
                  >
                    <span>发布项目</span>
                  </Button>
                </div>
                <div>
                  <Button
                    className={classNames(classes.menuButton, classes.hide)}
                    aria-label="Job request list"
                    onClick={() => {
                      navigate('/company');
                    }}
                  >
                    <span>招聘管理</span>
                  </Button>
                </div>
                {/* <div>
                  <Button
                    className={classNames(classes.menuButton, classes.hide)}
                    aria-label="Job request list"
                    onClick={() => {
                      navigate('/maps');
                    }}
                  >
                    <span>地图查找</span>
                  </Button>
                </div> */}
              </>
            )}
          </div>
          {user.userType == FUserTypeEnum.COMPANY && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/company');
              }}
            >
              企业招聘中心
            </span>
          )}
          <div className={classes.menuRight}>
            <div className={classes.search}>
              <SearchIcon />
              <InputBase
                placeholder="找项目…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onKeyDown={keyByEnter}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>

            {user?.id ? (
              <>
                {user.userType == FUserTypeEnum.CONSULTANT && (
                  <div
                    style={{ margin: '0 1rem', cursor: 'pointer' }}
                    onClick={() => {
                      navigate('/consultant/resume');
                    }}
                  >
                    简历
                  </div>
                )}
                <div className={classes.sectionDesktop}>
                  <Typography className={classes.usernameDisplay}>
                    {user.userType === FUserTypeEnum.CONSULTANT && <AccountCircle />}
                    {user.userType === FUserTypeEnum.COMPANY && <BusinessIcon />}
                    {(!user.userType || user.userType === FUserTypeEnum.ADMIN) && <AccountCircle />}
                    {email}
                  </Typography>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
              </>
            ) : (
              <div className={classes.action}>
                <span className={classes.actionText} onClick={() => navigate('/consultant/login')}>
                  登录
                </span>
                <span className={classes.registerText}>
                  /
                  <span
                    className={classes.actionText}
                    onClick={() => navigate('/consultant/register')}
                  >
                    注册
                  </span>
                </span>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* {!userDisplays && renderMobileMenu} */}
      {!userDisplays && renderMenu}
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  title?: string;
  projectDisplays?: boolean;
  userDisplays?: boolean;
}

export default withStyles(styles)(ConsultantHeader);
