import React, { ReactElement, useState } from 'react';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core';
import { Select } from 'antd';

import CITY from '../../utils/city';

const styles = (): StyleRules =>
  createStyles({
    cityContainer: {
      background: '#fff',
      padding: '1rem 0',
    },
    citySelect: {
      paddingLeft: '1rem',
      marginBottom: '2rem',
      color: '#242f65',
    },
    cityList: {
      borderRadius: '1rem',
      display: 'flex',
      flexWrap: 'wrap',
      height: 400,
      overflow: 'auto',
    },
    city: {
      padding: '0.5rem',
      margin: '0 0.5rem',
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      flex: '1 1 10rem',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      color: '#242f65',
      '&:hover': {
        background: '#9b9bbe',
        color: '#fff',
      },
    },
    icons: {
      margin: '0 1rem',
      '&:hover': {
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      },
    },
  });

const ConsultantCityListView = ({
  classes,
  selectCity,
  selectValue,
  onClose,
}: Props): ReactElement => {
  const [selectedCity, setSelectedCity] = useState('');

  const cityList = CITY.map(item => {
    const { code, name } = item;
    return {
      value: code,
      label:
        code === '1101'
          ? '北京市'
          : code === '1201'
          ? '天津市'
          : code === '3101'
          ? '上海市'
          : code === '5001'
          ? '重庆市'
          : name,
    };
  });

  const onSelect = (value?: string) => {
    if (!value) return;
    setSelectedCity(value);
    selectCity && selectCity(value);
  };

  return (
    <div className={classes.cityContainer}>
      {!selectValue && (
        <div className={classes.citySelect}>
          <span className={classes.citySelectLabel}>请选择城市：</span>
          <Select
            value={selectedCity}
            placeholder="请选择城市"
            showSearch
            allowClear
            optionFilterProp="label"
            style={{
              width: 200,
            }}
            onSelect={onSelect}
            options={cityList}
          />
        </div>
      )}
      <div className={classes.cityList}>
        {cityList.map((item, index) => {
          return (
            <div
              key={index}
              className={classes.city}
              onClick={() => {
                selectCity && selectCity(item.value);
                selectValue && selectValue(item.value);
                onClose && onClose();
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  selectCity?: (val: string) => void;
  selectValue?: (val: string) => void;
  onClose?: Function;
}

export default withStyles(styles)(ConsultantCityListView);
